import { render, staticRenderFns } from "./CriarAcessorioModal.vue?vue&type=template&id=4eb6d2b8&scoped=true&"
import script from "./CriarAcessorioModal.vue?vue&type=script&lang=js&"
export * from "./CriarAcessorioModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eb6d2b8",
  null
  
)

export default component.exports