import { render, staticRenderFns } from "./CriarModeloModal.vue?vue&type=template&id=07d4713a&scoped=true&"
import script from "./CriarModeloModal.vue?vue&type=script&lang=js&"
export * from "./CriarModeloModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07d4713a",
  null
  
)

export default component.exports