import Vue from 'vue'
import VueRouter from 'vue-router'
import ImpAcessoriosHome from "./ImpAcessoriosHome";
import ImpAcessoriosModelo from "./ImpAcessoriosModelo";
import ImpDetalheAcessorio from "@/acessoriosimp/ImpDetalheAcessorio";

Vue.use(VueRouter)

export default new VueRouter({
    routes: [
        {
            path: '/',
            name: 'ImpAcessoriosHome',
            component: ImpAcessoriosHome
        },
        {
            path: '/modelo/:modeloId/acessorios',
            name: 'ImpAcessoriosModelo',
            component: ImpAcessoriosModelo,
            props: true
        },
        {
            path: '/acessorio/:id',
            name: 'ImpDetalheAcessorio',
            component: ImpDetalheAcessorio,
            props: true
        },
    ]
})
