import Vue from 'vue'
import VueRouter from 'vue-router'
import ConcAcessoriosHome from "./ConcAcessoriosHome";
import ConcAcessoriosModelo from "./ConcAcessoriosModelo";
import ConcDetalheAcessorio from "@/acessoriosconc/ConcDetalheAcessorio";

Vue.use(VueRouter)

export default new VueRouter({
    routes: [
        {
            path: '/',
            name: 'ConcAcessoriosHome',
            component: ConcAcessoriosHome
        },
        {
            path: '/modelo/:modeloId/acessorios',
            name: 'ConcAcessoriosModelo',
            component: ConcAcessoriosModelo,
            props: true
        },
        {
            path: '/acessorio/:id',
            name: 'ConcDetalheAcessorio',
            component: ConcDetalheAcessorio,
            props: true
        },
    ]
})
