<template>
    <div id="carousel-acc" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner" role="listbox">
            <div v-for="(image, index) in images" :key="index" :class="index === 0 ? 'item active' : 'item'">
                <a href="#carousel-acc" data-slide="next">
                    <img :src="image" />
                </a>
            </div>
        </div>

        <!-- Controls -->
        <a class="left carousel-control" href="#carousel-acc" role="button" data-slide="prev">
            <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="right carousel-control" href="#carousel-acc" role="button" data-slide="next">
            <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        images: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            index: 0,
        }
    }
}
</script>

<style scoped>

</style>
