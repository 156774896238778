<template>
    <div>
        <div class="col-sm-12">
            <h3 style="margin-top:0;">
                Acessórios
            </h3>
            <hr/>
            <h4>
                Modelos
                <button v-if="permissoesEscrita"
                        class="btn btn-primary"
                        v-on:click="showCriar = true">
                    <i class="fa fa-cog"></i> Criar Modelo
                </button>
            </h4>
            <div v-if="permissoesEscrita" class="alert alert-info">Arrastar imagens para reordenar</div>
        </div>
        <div class="col-sm-12">
            <div style="margin-top:10px;"></div>
            <div v-if="loading">
                <i class="fa fa-2x fa-spinner fa-spin"></i>
            </div>
            <div class="row" v-if="!loading">
                <draggable :disabled="!permissoesEscrita" v-model="modelos" group="modelos" @change="updateOrdem">
                    <div class="col-md-3 col-sm-6 col-xs-12" style="margin-bottom: 30px;" v-for="modelo in modelos" :key="modelo.id">
                        <div class="model-container">
                            <div class="img-container">
                                <img class="model-img" :src="modeloFicheiroUrl(modelo.id)" @click="openModel(modelo)">
                            </div>
                            <div>
                                <div style="font-weight: bold;text-align: center;">{{modelo.nome}}</div>
                                <div style="display:flex;justify-content: space-between;">
                                    <button class="btn btn-sm btn-default" @click="openEdit(modelo)"><i class="fa fa-edit"></i></button>
                                    <button class="btn btn-sm btn-danger" @click="deleteModelo(modelo)"><i class="fa fa-trash-o"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </draggable>
            </div>
        </div>
        <criar-modelo-modal :show.sync="showCriar" @refresh="loadModelos"></criar-modelo-modal>
        <editar-modelo-modal :show.sync="showEditar" :modelo="modeloEdit" @refresh="loadModelos"></editar-modelo-modal>
    </div>

</template>
<style lang="css" scoped>
.model-container {
    margin-right: 0;
    margin-left: 0;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.img-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.model-img {
    max-width:100%;
    max-height:188px;
    vertical-align: middle;
}

.model-img:hover {
    cursor: pointer;
}
</style>
<script>
    // @ is an alias to /src
    import axios from 'axios'
    import CriarModeloModal from "@/acessoriosimp/modal/CriarModeloModal";
    import {handleAjaxError} from "@/utils";
    import EditarModeloModal from "@/acessoriosimp/modal/EditarModeloModal";
    import draggable from 'vuedraggable'

    export default {
        components: {
            CriarModeloModal,
            EditarModeloModal,
            draggable,
        },
        data() {
            return {
                showCriar: false,
                loading: false,

                permissoesEscrita: false,

                modelos: [],

                showEditar: false,
                modeloEdit: {
                    id: null,
                    nome: null,
                }
            }
        },
        mounted() {
            // eslint-disable-next-line
            this.permissoesEscrita = permEsc;
            // this.permissoesEscrita = true;
            this.loadModelos();
        },
        computed: {
        },
        methods: {
            openModel(modelo) {
                this.$router.push({ name: 'ImpAcessoriosModelo', params: { modeloId: modelo.id, modeloNome: modelo.nome } })
            },
            openEdit(modelo) {
                this.modeloEdit = modelo;
                this.showEditar = true;
            },
            deleteModelo(modelo) {
                const self = this;
                const msg = "Tem a certeza que deseja apagar o modelo?";
                // eslint-disable-next-line
                showCustomDialog("Confirmação", msg, "Apagar", "btn-danger", function(){
                    // eslint-disable-next-line
                    const url = jsRoutes.controllers.ImpAcessoriosController.apagarModelo(modelo.id).url;
                    axios.get(url).then(() => {
                        // eslint-disable-next-line
                        showSuccess("Sucesso", "Modelo apagado");
                        self.loadModelos();
                    }, function(error) {
                        handleAjaxError(error, "Erro ao apagar modelo");
                    });
                });
            },
            modeloFicheiroUrl(id) {
                // eslint-disable-next-line
                return jsRoutes.controllers.ImpAcessoriosController.modeloFicheiro(id).url;
            },
            loadModelos() {
                const self = this;
                this.loading = true;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpAcessoriosController.loadModelos().url;

                // GET request
                axios.get(url).then(function (response) {
                    self.loading = false;
                    // set data
                    self.modelos = response.data;
                }).catch(function (error) {
                    self.loading = false;
                    // handle error
                    // eslint-disable-next-line
                    handleAjaxError(error, "Erro ao carregar lista de modelos");
                });

            },
            updateOrdem() {
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpAcessoriosController.editarModelosOrdem().url;

                let dataObj = {
                    ids: []
                };
                this.modelos.forEach(a => {
                    dataObj.ids.push(a.id);
                });

                axios.post(url, dataObj).then(function () {
                    // eslint-disable-next-line
                    showSuccess('Sucesso', 'Ordem actualizada');
                }, function (error) {
                    // eslint-disable-next-line
                    handleAjaxError(error, 'Ocorreu um erro ao actualizar ordem de modelos');
                });
            }
        }
    }
</script>
