<template>
    <div class="modal" tabindex="-1" role="dialog" ref="modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h5 class="modal-title">Criar Modelo - Acessórios</h5>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <form novalidate v-on:submit.prevent="criarModelo">
                            <div class="form-group" :class="{'has-error' : $v.filename.$error}">
                                <label>Foto *:</label><span v-if="filename !== null" class="form-control-static">&nbsp;{{filename}}</span><br/>
                                <input ref="fileupload" class="hide" type="file"
                                       accept="image/*"
                                       @change="fileInputChange"
                                >
                                <button class="btn btn-primary" type="button" v-on:click.prevent="fileUploadClick">Carregar ficheiro</button>
<!--                                <b v-if="filename !== null">Ficheiro: </b>-->
                                <span class="help-block"
                                      v-show="!$v.filename.required && $v.filename.$error">Campo obrigatório</span>
                            </div>
                            <div class="form-group" :class="{'has-error' : $v.nome.$error}">
                                <label>Nome *</label>
                                <input type="text" class="form-control" v-model="nome"/>
                                <span class="help-block"
                                      v-show="!$v.nome.required && $v.nome.$error">Campo obrigatório</span>
                                <span class="help-block"
                                      v-show="!$v.nome.maxLength && $v.nome.$error">O campo tem de ter menos de 100 caracteres</span>
                            </div>

<!--                            <div class="pull-right">
                                <button class="btn btn-link" @click="close">Cancelar</button>
                                <v-button class="btn btn-primary" :loading="loading" @click.native="criarModelo">Criar Modelo</v-button>
                            </div>-->
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
                    <v-button type="button" class="btn btn-primary"
                              :loading="loading"
                              v-on:click.prevent="criarModelo">
                        Criar Modelo</v-button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    import VButton from "../../components/VButton";
    import {maxLength, required} from "vuelidate/lib/validators";
    import {handleAjaxError} from "@/utils";

    export default {
        components: {
            VButton,
        },
        props: {
            show: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                loading: false,

                nome: null,
                filename: null,
                file: null,
            }
        },
        validations: {
                nome: {
                    required,
                    maxLength: maxLength(100),
                },
                filename: {
                    required,
                },
            },
            mounted() {
            let self = this;
            // eslint-disable-next-line
            $(this.$refs.modal).on('hidden.bs.modal', function (e) {
                self.close()
            });

        },
        watch: {
            show() {
                if (this.show) {
                    // eslint-disable-next-line
                    $(this.$refs.modal).modal('show');
                    this.nome = null;
                    this.filename = null;
                    this.file = null;
                    this.$v.$reset();
                } else {
                    $(this.$refs.modal).modal('hide');
                }
            },
        },
        computed: {
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            fileUploadClick() {
                this.$refs.fileupload.click();
            },
            fileInputChange(e) {
                const files = e.target.files || e.dataTransfer.files;
                if (files.length > 0) {
                    this.file = files[0];
                    this.filename = this.file.name;

                    // const self = this;
                    const reader = new FileReader();
                    /*reader.onload = function(e) {
                        $(self.$els.fileimg).attr('src', e.target.result);
                    };*/

                    reader.readAsDataURL(this.file);
                } else {
                    this.file = null;
                    this.filename = null;
                }
            },
            criarModelo() {
                const self = this;
                this.$v.$touch()
                if (this.$v.$invalid) {
                    // console.error('data invalid');
                    return;
                }

                let form = new FormData();
                form.append("nome", this.nome);
                form.append("filename", this.filename);

                if (this.file !== null) {
                    try {
                        form.append('Content-Type', this.file.type || 'application/octet-stream');
                        // our request will have the file in the ['file'] key
                        form.append('file', this.file);
                        // eslint-disable-next-line
                    } catch (err) {
                    }
                }
                self.loading = true;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpAcessoriosController.criarModelo().url;

                axios.post(url, form, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then(function () {
                    self.loading = false;
                    // eslint-disable-next-line
                    showSuccess('Sucesso', 'Modelo Criado');
                    self.$emit('refresh');
                    self.close();
                }, function (error) {
                    self.loading = false;
                    // eslint-disable-next-line
                    handleAjaxError(error, 'Ocorreu um erro ao criar modelo');
                });


            },
        }
    }
</script>

<style scoped>

</style>
