import { render, staticRenderFns } from "./ImpDetalheAcessorio.vue?vue&type=template&id=d8a97726&scoped=true&"
import script from "./ImpDetalheAcessorio.vue?vue&type=script&lang=js&"
export * from "./ImpDetalheAcessorio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8a97726",
  null
  
)

export default component.exports