<template>
    <div>
        <div class="col-sm-12">
            <h4>
                <router-link :to="{name :'ConcAcessoriosHome'}">
                    <i class="fa fa-arrow-left"></i> Voltar
                </router-link>
            </h4>
            <h3>
                Acessórios - <router-link :to="{name :'ConcAcessoriosHome'}">
                    {{modeloNome}}
                </router-link>
            </h3>
            <hr/>
<!--            <h4 style="margin-top:0;">
                Acessórios
            </h4>-->
        </div>
        <div class="col-sm-12">
            <div style="margin-top:10px;"></div>
            <div v-if="loading">
                <i class="fa fa-2x fa-spinner fa-spin"></i>
            </div>
            <div class="row" v-if="!loading">
                <div class="col-md-3 col-sm-6 col-xs-12" style="margin-bottom: 30px;" v-for="acessorio in acessorios" :key="acessorio.id">
                    <div class="model-container">
                        <div class="img-container">
                            <img class="model-img" :src="acessorioFicheiroUrl(acessorio.ficheiroId)" @click="openAcessorio(acessorio.id)">
                        </div>
                        <div>
                            <div style="font-weight: bold;text-align: center;">{{acessorio.nome}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<style lang="css" scoped>
.model-container {
    margin-right: 0;
    margin-left: 0;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.img-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.model-img {
    max-width:100%;
    max-height:188px;
    vertical-align: middle;
}

.model-img:hover {
    cursor: pointer;
}
</style>
<script>
    // @ is an alias to /src
    import axios from 'axios'
    import {handleAjaxError} from "@/utils";

    export default {
        components: {

        },
        props: ["modeloId"],
        data() {
            return {
                loading: false,

                modeloNome: "",
                acessorios: [],
            }
        },
        mounted() {
            this.loadAcessorios();

        },
        computed: {
        },
        methods: {
            openAcessorio(id) {
                this.$router.push({ name: 'ConcDetalheAcessorio', params: { id: id } })
            },
            acessorioFicheiroUrl(id) {
                // eslint-disable-next-line
                return jsRoutes.controllers.ConcAcessoriosController.acessorioFicheiro(id).url;
            },
            loadAcessorios() {
                const self = this;
                this.loading = true;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ConcAcessoriosController.loadAcessorios(this.modeloId).url;

                // GET request
                axios.get(url).then(function (response) {
                    self.loading = false;
                    // set data
                    self.modeloNome = response.data.modeloNome;
                    self.acessorios = response.data.acessorios;
                }).catch(function (error) {
                    self.loading = false;
                    // handle error
                    // eslint-disable-next-line
                    handleAjaxError(error, "Erro ao carregar lista de acessórios");
                });

            },
        }
    }
</script>
