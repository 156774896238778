import Dropzone from "dropzone";
Dropzone.autoDiscover = false;

// eslint-disable-next-line
moment.locale("pt");

import './assets/css/styles.css'

import Vue from 'vue'

Vue.config.productionTip = false

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);


import axios from "axios";
import ImpAcessoriosApp from "./acessoriosimp/ImpAcessoriosApp";
import impAcessoriosRouter from "@/acessoriosimp/impAcessoriosRouter";
import ConcAcessoriosApp from "./acessoriosconc/ConcAcessoriosApp";
import concAcessoriosRouter from "@/acessoriosconc/concAcessoriosRouter";

if(document.getElementById("concAcessoriosContainer")) {
    new Vue({
        router: concAcessoriosRouter,
        render: h => h(ConcAcessoriosApp)
    }).$mount('#concAcessoriosContainer');
}

if(document.getElementById("impAcessoriosContainer")) {
    new Vue({
        router: impAcessoriosRouter,
        render: h => h(ImpAcessoriosApp)
    }).$mount('#impAcessoriosContainer');
}


if(document.getElementById("concPartial")) {
    new Vue({
        el: '#concPartial',
        data: {
            concId: null,
            concessionarios: [],
            teste: [
                {
                    id: 1,
                    text: "teste 1"
                },
                {
                    id: 2,
                    text: "teste 2"
                },
            ],
            firstLoad: true
        },
        mounted: function () {
            this.loadDetails();

        },
        watch: {
            concId: function () {
                if (!this.firstLoad) {
                    // eslint-disable-next-line
                    const url = jsRoutes.controllers.Application.updateCurrentConcessionario(this.concId).url;
                    axios.get(url).then(function () {
                        // success callback
                        // eslint-disable-next-line
                        reloadPage();
                    }, function () {
                        // eslint-disable-next-line
                        showError("Erro", "Erro ao mudar de concessionário");

                    });
                } else {
                    this.firstLoad = false;
                }
            }
        },
        methods: {
            loadDetails: function () {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.Application.loadUserConcessionarioDetails().url;

                axios.get(url).then(function (response) {
                    // success callback
                    self.concId = response.data.concId;
                    self.concessionarios = response.data.concessionarios;
                }, function () {
                    // eslint-disable-next-line
                    showError("Erro", "Erro ao carregar detalhes de concessionário");
                });
            },
        },
    })
}
