<template>
    <div class="modal" tabindex="-1" role="dialog" ref="modal">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
                    <h5 class="modal-title">Editar Acessório - {{acessorio.nome}}</h5>
                </div>
                <div class="modal-body">
                    <form novalidate v-on:submit.prevent="editarAcessorio">
                        <div class="form-group" :class="{'has-error' : $v.nome.$error}">
                            <label>Nome *</label>
                            <input type="text" class="form-control" v-model="nome"/>
                            <span class="help-block"
                                  v-show="!$v.nome.required && $v.nome.$error">Campo obrigatório</span>
                            <span class="help-block"
                                  v-show="!$v.nome.maxLength && $v.nome.$error">O campo tem de ter menos de 100 caracteres</span>
                        </div>
                        <div class="form-group" :class="{'has-error' : $v.ref.$error}">
                            <label>ref *</label>
                            <input type="text" class="form-control" v-model="ref"/>
                            <span class="help-block"
                                  v-show="!$v.ref.required && $v.ref.$error">Campo obrigatório</span>
                            <span class="help-block"
                                  v-show="!$v.ref.maxLength && $v.ref.$error">O campo tem de ter menos de 45 caracteres</span>
                        </div>
                        <div class="form-group" :class="{'has-error' : $v.descricao.$error}">
                            <label>Descrição *</label>
                            <input type="hidden" class="form-control" v-model="descricao"/>
                            <VueEditor v-model="descricao"/>
                            <span class="help-block"
                                  v-show="!$v.descricao.required && $v.descricao.$error">Campo obrigatório</span>
                        </div>
                        <div class="form-group" :class="{'has-error' : showFilesError}">
                            <label>Ficheiros</label>
                            <ul class="list-group">
                                <li v-for="(ficheiro, index) in ficheiros" :key="ficheiro.id" class="list-group-item" style="display: flex; justify-content: space-between;">
                                    <a :href="acessorioFicheiroUrl(ficheiro.id)">{{ficheiro.filename}}</a>
                                    <button @click.prevent="removerFicheiro(index)" class="btn btn-sm btn-danger"><i class="fa fa-trash-o"></i> </button>
                                </li>
                            </ul>
                            <div class="row" style="margin-bottom: 10px;">
                                <div class="col-sm-4" v-for="(id, index) in imagens" :key="id">
                                    <img style="width:100%;" :src="acessorioFicheiroUrl(id)">
                                    <button class="btn btn-danger btn-sm"
                                            style="position:absolute; bottom:0;right:15px;"
                                            v-tooltip="'Remover'"
                                            @click.prevent="removerImagem(index)"
                                    >
                                        <i class="fa fa-trash-o"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="dropzone" ref="dropzonearea">
                                <div class="dz-message">
                                    <h3>Arrastar ficheiros para carregar</h3><br>
                                    <span class="note">(Ou clicar com o rato para selecionar ficheiros)</span>
                                </div>
<!--                                    <select id="filesData" name="files" multiple="multiple" hidden>
                                </select>-->
                            </div>
                            <span class="help-block"
                                  v-show="showFilesError">Adicione pelo menos um ficheiro</span>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
                    <v-button type="button" class="btn btn-primary"
                              :loading="loading"
                              v-on:click.prevent="editarAcessorio">
                        Guardar
                    </v-button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Dropzone from "dropzone";

Dropzone.autoDiscover = false;

import axios from 'axios'

import VButton from "../../components/VButton";
import {maxLength, required} from "vuelidate/lib/validators";
import {handleAjaxError} from "@/utils";
import { VueEditor } from "vue2-editor";

export default {
    components: {
        VButton,
        VueEditor
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        acessorio: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,

            nome: null,
            ref: null,
            descricao: null,
            imagens: [],
            ficheiros: [],
            showFilesError: false,
        }
    },
    validations: {
        nome: {
            required,
            maxLength: maxLength(45),
        },
        ref: {
            required,
            maxLength: maxLength(100),
        },
        descricao: {
            required,
        },
    },
    mounted() {
        let self = this;

        this.setupDropzone();

        // eslint-disable-next-line
        $(this.$refs.modal).on('hidden.bs.modal', function (e) {
            self.close()
        });

    },
    watch: {
        show() {
            if (this.show) {
                // eslint-disable-next-line
                $(this.$refs.modal).modal('show');
                this.nome = this.acessorio.nome;
                this.ref = this.acessorio.ref;
                this.descricao = this.acessorio.descricao;
                this.imagens = [...this.acessorio.imagens];
                this.ficheiros = [...this.acessorio.ficheiros];
                this.showFilesError = false;
                this.dropzone.removeAllFiles(true);

            } else {
                $(this.$refs.modal).modal('hide');
            }
        },
    },
    computed: {},
    methods: {
        close() {
            this.$emit('update:show', false);
        },
        setupDropzone: function () {
            const self = this;
            this.dropzone = new Dropzone(this.$refs.dropzonearea, {
                url: "url", //dropzone.js:434 Uncaught Error: No URL provided
                paramName: "file", // The name that will be used to transfer the file
                maxFilesize: 50, // MB
                autoProcessQueue: false,
                uploadMultiple: true,
                addRemoveLinks: true,
                // acceptedFiles: ".jpg, .jpeg, .png, .bmp, .tif, .tiff",
                error: function (file, errorMessage) {//if the error was due to the XMLHttpRequest the xhr object as third
                    // eslint-disable-next-line
                    showError("Erro", errorMessage);
                    // self.uploadSuccess = false;
                    this.removeAllFiles(true);
                    self.dropzone.processQueue();
                },

                success: function (file/*, response*/) {
                    // console.log("#### success");
                    // self.uploadSuccess = true;
                    // self.files.push(response);
                    self.dropzone.processQueue();
                    this.removeFile(file);
                },

                queuecomplete: function () {
                    console.log("#### queuecomplete");
                    // if (self.uploadSuccess) {
                    //     // showSuccess("Sucesso", "Ficheiro carregado");
                    //     if (self.files.length > 0) {
                    //         self.filesValid = true;
                    //     }
                    // }
                },
            });
            // this.dropzoneSetup = true;
        },
        removerFicheiro(index) {
            console.log("remover ficheiro " + index);
            this.ficheiros.splice(index, 1)
        },
        removerImagem(index) {
            console.log("remover imagem " + index);
            this.imagens.splice(index, 1)
        },
        acessorioFicheiroUrl(id) {
            // eslint-disable-next-line
            return jsRoutes.controllers.ImpAcessoriosController.acessorioFicheiro(id).url;
        },
        editarAcessorio() {
            const self = this;
            this.$v.$touch()
            this.showFilesError = this.dropzone.files.length === 0 && this.ficheiros.length === 0;

            if (this.$v.$invalid || this.showFilesError) {
                console.error('data invalid');
                return;
            }


            let formData = new FormData();
            formData.append("id", this.acessorio.id + "");
            formData.append("nome", this.nome);
            formData.append("ref", this.ref);
            formData.append("descricao", this.descricao);

            let ficheirosIds = this.ficheiros.map(f => f.id);
            formData.append("ficheiros", [...this.imagens, ...ficheirosIds].join(","));

            const files = this.dropzone.files;
            files.forEach(function (value, index) {
                formData.append("ficheiro_" + index, value);
            });

            self.loading = true;
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpAcessoriosController.editarAcessorio().url;

            axios.post(url, formData).then(function () {
                self.loading = false;
                // eslint-disable-next-line
                showSuccess('Sucesso', 'Acessório actualizado');
                self.$emit('refresh');
                self.close();
            }, function (error) {
                self.loading = false;
                // eslint-disable-next-line
                handleAjaxError(error, 'Ocorreu um erro ao actualizar acessório');
            });


        },
    }
}
</script>

<style scoped>

</style>
