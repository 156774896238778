import $ from 'jquery';

/**
 * handle de erros de axios
 *
 * @param error objecto devolvido pelo metodo catch do axios
 */
export function handleAjaxError(error, title) {
    if (error.response !== undefined) {
        if (error.response.data) {
            if (error.response.data.indexOf("<!DOCTYPE html>") > -1) {
                var errorEl = $(error.response.data).find("#error");
                let message = ''
                if (errorEl !== null && errorEl.length > 0)
                    message = $(error.response.data).find("#error").html();
                else {
                    message = $(error.response.data).filter("title").text();
                }
                // eslint-disable-next-line
                new PNotify({
                    title: title ? title : "Erro",
                    text: message,
                    type: 'error'
                });
            } else if (error.response.data) {
                // console.log("error.response.data => " + error.response.data)
                // eslint-disable-next-line
                new PNotify({
                    title: title ? title : "Erro",
                    text: error.response.data,
                    type: 'error'
                });
            } else {
                // console.log("error.message => " + error.message)
                // eslint-disable-next-line
                new PNotify({
                    title: title ? title : "Erro",
                    text: error.message,
                    type: 'error'
                });
            }
        } else {
            // console.log("error.message #2 => " + error.message)
            // eslint-disable-next-line
            new PNotify({
                title: title ? title : "Erro",
                text: error.message,
                type: 'error'
            });
        }
        /*if (error.response.status === 500) {
            toastr.error(error.message, "Error");
        } else {
            toastr.error(error.response.data, "Error");
        }*/
    } else if (error.responseText !== undefined) {
        if (error.responseText.trim().length > 0) {
            // console.log("error.responseText => " + error.responseText)
            // eslint-disable-next-line
            new PNotify({
                title: title ? title : "Erro",
                text: error.responseText,
                type: 'error'
            });
        } else {
            // console.log("error.statusText => " + error.statusText)
            // eslint-disable-next-line
            new PNotify({
                title: title ? title : "Erro",
                text: error.statusText,
                type: 'error'
            });
        }
    } else if (error.message) {
        // console.log("error.message #3 => " + error.message)
        // eslint-disable-next-line
        new PNotify({
            title: title ? title : "Erro",
            text: error.message,
            type: 'error'
        });
    } else {
        // console.log("error => " + error)
        // eslint-disable-next-line
        new PNotify({
            title: title ? title : "Erro",
            text: error,
            type: 'error'
        });
    }
}


