<template>
    <div class="col-sm-12">
        <div v-show="loading">
            <i class="fa fa-spin fa-spinner fa-2x"></i>
        </div>
        <div v-show="!loading">
            <div class="col-sm-12">
                <h4>
                    <router-link :to="{name :'ConcAcessoriosModelo', params: {modeloId: detalhe.modeloId, modeloNome: detalhe.modeloNome}}">
                        <i class="fa fa-arrow-left"></i> Voltar
                    </router-link>
                </h4>
                <h3>Acessórios</h3>
                <hr/>
            </div>
            <div class="col-sm-12 col-md-6">
                <carousel :images="imagens"></carousel>
            </div>
            <div class="col-sm-12 col-md-6">
                <h2 style="margin-top: 0;">{{detalhe.nome}}</h2>
                <h5>REF: {{detalhe.ref}}</h5>
                <p v-html="detalhe.descricao"></p>
                <hr/>
                <ul class="list-group">
                    <li v-for="ficheiro in detalhe.ficheiros" :key="ficheiro.id" class="list-group-item">
                        <a :href="acessorioFicheiroUrl(ficheiro.id)">
                            <i class="fa fa-download"></i> {{ficheiro.filename}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<style scoped>

</style>
<script>
    // @ is an alias to /src
    import axios from "axios";
    import Carousel from "@/components/Carousel";

    export default {
        components: {
            Carousel
        },
        props: ["id"],
        data() {
            return {
                detalhe: {
                    assunto: null,
                    descricao: null,
                    dataCriacao: null,
                    respostas: [],
                    ficheiros: [],
                },

                imagens: [],

                loading: false,

            }
        },
        mounted() {
            this.loadDetalhe();
        },
        computed: {
        },
        methods: {
            acessorioFicheiroUrl(id) {
                // eslint-disable-next-line
                return jsRoutes.controllers.ConcAcessoriosController.acessorioFicheiro(id).url;
            },
            loadDetalhe() {
                this.loading = true;
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ConcAcessoriosController.getDetalheAcessorio(this.id).url;

                // GET request
                axios.get(url).then(function (response) {
                    self.loading = false;
                    // set data
                    // eslint-disable-next-line
                    self.detalhe = response.data;
                    self.imagens = [];
                    self.detalhe.imagens.forEach(fid => {
                        self.imagens.push(self.acessorioFicheiroUrl(fid));
                    })
                }).catch(function (error) {
                    self.loading = false;
                    // handle error
                    // eslint-disable-next-line
                    handleAjaxError("Erro ao obter detalhe de acessório", error);
                });
            },
        }
    }
</script>
